
import { defineComponent } from "vue";
import Main from "@/templates/Main.vue";

export default defineComponent({
  name: "PrivacyPolicy",
  components: {
    Main,
  },
});
